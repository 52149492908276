<div class="navbar navbar-expand-lg fixed-top header">
    <div class="container-fluid">
        <!--------------------------- Start - TOP NAV BAR --------------------------->

        <!-- Left part -->
        <ng-container>
            <button class="head__menu collapsed" style="z-index: 5" type="button" id="clickhead" (click)="toggleSideMenu()">
                <span></span>
                <span></span>
                <span></span>
            </button>

            <a style="z-index: 2; cursor: default !important" class="navbar-brand">
                <img class="cursor-pointer" [routerLink]="['/', 'corporate', 'live-tracking']" src="/assets/app-img/home1.svg" />
            </a>

            <!-- Added New Ride Reuest Button 30 jan 2024 -->
            <div class="new-ride d-flex" *ngIf="!router.url?.includes('live-tracking') || utilityService._showNewRideButton">
                <img src="assets/images/navbar_divider.svg" />
                <button class="new-ride-request collapsed" style="z-index: 5" type="button" (click)="onNewRide()">
                    New Ride Request
                    <mat-icon>send</mat-icon>
                </button>
            </div>
        </ng-container>

        <!-- Middle Part -->
        <div class="pos-f-t ml-auto">
            <ul class="bar dropdown-pad">
                <li class="mr-3 nav-item">
                    <!-- <div
                        class="py-1 cursor-pointer text-white"
                        [popover]="tripSearchPopover"
                        [outsideClick]="true"
                        containerClass="w-260 br-6"
                        #tripSearchPopoverRef="bs-popover"
                        placement="bottom"
                        container="body"
                        (onShown)="tripSearchPopoverReference = tripSearchPopoverRef; searchTripById()"
                    >
                        
                    </div> -->
                    <app-add-shortcut-popover>
                        <div class="hover-bold">
                            <img src="assets/app-img/add_new.svg" style="height: 28px" />
                            <span *ngIf="!ipadSize" class="fs-12 va-middle text-white">Add</span>
                        </div>
                    </app-add-shortcut-popover>
                </li>
                <li class="nav-item under navbar-divider mr-2" *ngIf="!ipadSize" [class.d-none]="mobileSize">
                    <img src="assets/images/navbar_divider.svg" />
                </li>
                <li class="mr-3" *ngIf="is_mas_dispatcher">
                    <div
                        class="py-1 cursor-pointer"
                        [class.nav-search-popover-label]="!ipadSize && !mobileSize"
                        [popover]="tripSearchPopover"
                        [outsideClick]="true"
                        containerClass="w-350 br-6"
                        #tripSearchPopoverRef="bs-popover"
                        placement="bottom"
                        container="body"
                        (onShown)="tripSearchPopoverReference = tripSearchPopoverRef; searchTripById()"
                    >
                        <ng-container *ngIf="!ipadSize && !mobileSize">
                            Search Trips
                            <span class="ml-1">
                                <i class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="ipadSize || mobileSize">
                            <!-- <i class="fa fa-search fs-16" style="color: white" aria-hidden="true"></i> -->
                            <img src="assets/img/search_white.svg" />
                        </ng-container>
                    </div>
                </li>
                <li class="nav-item under navbar-divider" [class.d-none]="mobileSize" *ngIf="is_mas_dispatcher">
                    <img src="assets/images/navbar_divider.svg" />
                </li>
                <li class="nav-item under">
                    <a (click)="navigateToTripRoster()">
                        <img src="/assets/images/active-trip-logo.svg" />
                        &nbsp;
                        <span class="ipad-hidden">Trip Roster</span>
                    </a>
                </li>
                <li class="mr-3" *ngIf="!router.url?.includes('active-trips') && !ipadSize">
                    <div
                        class="nav-search-popover-label py-1 cursor-pointer"
                        [popover]="riderSearchPopover"
                        [outsideClick]="true"
                        containerClass="w-350 br-6"
                        #riderSearchPopoverRef="bs-popover"
                        placement="bottom"
                        container="body"
                        (click)="riderSearchPopoverReference = riderSearchPopoverRef"
                    >
                        Search Riders
                        <span class="ml-1">
                            <i class="fa fa-sort-desc" aria-hidden="true"></i>
                        </span>
                    </div>
                </li>
                <li class="mr-3" *ngIf="!router.url?.includes('active-trips') && !ipadSize">
                    <div
                        class="nav-search-popover-label py-1 cursor-pointer"
                        [popover]="driverSearchPopover"
                        [outsideClick]="true"
                        containerClass="w-350 br-6"
                        #driverSearchPopoverRef="bs-popover"
                        placement="bottom"
                        container="body"
                        (click)="driverSearchPopoverReference = driverSearchPopoverRef"
                    >
                        Search Driver
                        <span class="ml-1">
                            <i class="fa fa-sort-desc" aria-hidden="true"></i>
                        </span>
                    </div>
                </li>
                <li class="nav-item under navbar-divider" [class.d-none]="mobileSize">
                    <img src="assets/images/navbar_divider.svg" />
                </li>

                <li class="nav-item under provider dropdown" *ngIf="!mobileSize">
                    <ng-container [ngTemplateOutlet]="providerDropdown"></ng-container>
                </li>

                <li class="nav-item under dropdown" [class.d-none]="mobileSize">
                    <a class="dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <!-- <i class="fa fa-users"></i> -->
                        <img src="/assets/images/new-network-logo.svg" />
                        &nbsp;
                        <span class="ipad-hidden">Network</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right user network-menu mt-0" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" (click)="router.navigate(['/', 'corporate', 'riders', 'my-riders'])">
                            <i class="fa fa-user fa-fw"></i>
                            &nbsp; Riders
                        </a>
                        <hr class="rule" />
                        <a class="dropdown-item" (click)="router.navigate(['/', 'corporate', 'drivers', 'all-drivers'])">
                            <i class="fa fa-car fa-fw"></i>
                            &nbsp; Drivers
                        </a>
                    </div>
                </li>
                <li class="nav-item under navbar-divider" [class.d-none]="mobileSize">
                    <img src="assets/images/navbar_divider.svg" />
                </li>
                <li class="nav-item under" [class.d-none]="mobileSize">
                    <a class="" [routerLink]="['/', 'corporate', 'cards']">
                        <i class="fa fa-cog"></i>
                        <!-- <span class="ipad-hidden">&nbsp; Settings</span> -->
                    </a>
                </li>

                <li class="nav-item under" [class.d-none]="mobileSize">
                    <a class="" [routerLink]="['/', 'corporate', 'notifications', 'list']">
                        <i class="fa fa-exclamation-circle">
                            <sup
                                style="top: -0.8em; left: -0.6em"
                                [hidden]="!(totalNotifications > 0)"
                                class="notif-badge"
                                [innerText]="totalNotifications.toString().padStart(2, '0')"
                            ></sup>
                        </i>
                        <!-- <span class="ipad-hidden">&nbsp; Notifications</span> -->
                    </a>
                </li>

                <li class="nav-item under navbar-divider">
                    <img src="assets/images/navbar_divider.svg" />
                </li>
            </ul>
        </div>

        <li class="mr-md-3">
            <div class="py-1 cursor-pointer">
                <div class="d-flex align-items-center">
                    <a *ngIf="!mobileSize && corp_Type != 1">
                        <span *ngIf="corp_Type != 1" class="uname text-light">{{ driverDetails?.first_name | titlecase }}</span>
                    </a>

                    <a
                        *ngIf="!mobileSize && corp_Type == 1"
                        [popover]="approvedCarBase"
                        [outsideClick]="true"
                        containerClass="w-350 br-6 mr-1"
                        placement="bottom"
                        container="body"
                    >
                        <span *ngIf="corp_Type == 1" class="uname text-light">
                            {{ (selectedCorp?.business_name ? selectedCorp?.business_name : driverDetails?.first_name) | titlecase }}
                        </span>
                        <img *ngIf="corp_Type == 1" src="/assets/app-img/down_arrow.svg" alt="" width="15px" class="py-1" />
                    </a>
                    <img
                        [popover]="profileDropdownPopover"
                        [outsideClick]="true"
                        containerClass="w-350 br-6 mr-3"
                        #profileDropdownRef="bs-popover"
                        placement="bottom"
                        container="body"
                        (onShown)="profileDropdownPopoverReference = profileDropdownRef"
                        [src]="driverDetails.driver_image || driverDetails?.image"
                        id="dprofileimagetwo"
                        class="profile-image"
                        onError="src='/assets/images/default.png';"
                    />
                </div>
            </div>
        </li>
        <!--------------------------- END - TOP NAV BAR --------------------------->

        <!-- Side navbar -->
        <div id="mySideMenu" class="sideMenu" [ngStyle]="{ 'margin-left': isSideMenuOpened ? '0px' : '-220px' }">
            <div class="border-right side_navigation" id="sidebar-wrapper">
                <div class="list-group list-group-flush accordion mt-4" id="accordion">
                    <a class="list-group-item light pt-0" (click)="navigateToTripRoster()" (click)="isSideMenuOpened = false">
                        <img src="/assets/images/active-trip-logo.svg" style="width: 13px" />
                        &nbsp; Trip Roster
                    </a>
                    <a
                        [routerLink]="['/', 'corporate', 'attestation', 'all']"
                        (click)="isSideMenuOpened = false"
                        class="list-group-item light"
                        *ngIf="is_mas_dispatcher"
                    >
                        <img src="/assets/images/navbar_attestation.svg" />
                        &nbsp; Attestation
                    </a>
                    <!-- <a class="list-group-item dropdown_menu collapsed" aria-expanded="true">
                        <a [routerLink]="['/', 'corporate', 'completed-trips']" class="light text-white" (click)="isSideMenuOpened = false">
                            <span class="icons icon-scheduled_car"></span>
                            Trips History
                        </a>
                        Need hide when uncomment this 
                        <div id="network" class="dropMenu show">
                            <ul class="pt-2 pl-3">
                                <li>
                                    <a class="light" [routerLink]="['/', 'corporate', 'riders', 'my-riders']" (click)="isSideMenuOpened = false">
                                        <span class="icons icon-scheduled_car"></span>
                                        Trip History
                                    </a>
                                </li>
                                <li>
                                    <a class="light" (click)="isSideMenuOpened = false; utilityService.open_trip_by_rider()">
                                        <span class="icons icon-user"></span>
                                        Search Trips by Rider
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </a> -->

                    <!-- <a
                        class="list-group-item light fs-18"
                        [routerLink]="['/', 'corporate', 'transaction-history-all']"
                        (click)="isSideMenuOpened = false"
                    >
                        <mat-icon class="fs-18 va-middle">paid</mat-icon>
                        Transaction History
                    </a> -->

                    <a
                        class="list-group-item light fs-18"
                        [routerLink]="['/', 'corporate', 'earnings', 'overview']"
                        (click)="isSideMenuOpened = false"
                    >
                        <mat-icon class="fs-18 va-middle">paid</mat-icon>
                        Earnings
                    </a>

                    <a class="list-group-item light fs-18" [routerLink]="['/', 'corporate', 'drivers', 'payout']" (click)="isSideMenuOpened = false">
                        <mat-icon class="fs-18 va-middle">paid</mat-icon>
                        <!-- <img src="assets/images/report.svg" alt="report" style="height: 16px" /> -->
                        &nbsp; Driver Payout
                    </a>
                    <a class="list-group-item dropdown_menu collapsed" aria-expanded="true">
                        <a [routerLink]="['/', 'corporate', 'riders', 'my-riders']" class="light text-white" (click)="isSideMenuOpened = false">
                            <span class="icons icon-network"></span>
                            Network
                            <i class="fa drop-icon ml-2 fa-angle-down" aria-hidden="true"></i>
                        </a>
                        <div id="network" class="dropMenu show">
                            <ul class="pt-2 pl-3">
                                <li>
                                    <a class="light" [routerLink]="['/', 'corporate', 'riders', 'my-riders']" (click)="isSideMenuOpened = false">
                                        <span class="icons icon-user"></span>
                                        Riders List
                                    </a>
                                </li>
                                <li>
                                    <a class="light" [routerLink]="['/', 'corporate', 'drivers', 'all-drivers']" (click)="isSideMenuOpened = false">
                                        <span class="icons icon-scheduled_car"></span>
                                        <!-- QudosFave™ Driver -->
                                        Drivers List
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </a>

                    <a
                        class="list-group-item light fs-18 pt-0"
                        [routerLink]="['/', 'corporate', 'reports', 'overview']"
                        (click)="isSideMenuOpened = false"
                    >
                        <!-- <mat-icon class="fs-18 va-middle">chat_bubble</mat-icon> -->
                        <img src="assets/images/report.svg" alt="report" style="height: 16px" />
                        &nbsp; Reports
                    </a>

                    <a class="list-group-item light" [routerLink]="['/', 'corporate', 'cards']" (click)="isSideMenuOpened = false">
                        <span class="icons icon-notification"></span>
                        Settings
                    </a>
                    <a class="list-group-item light" [routerLink]="['/', 'corporate', 'notifications', 'list']" (click)="isSideMenuOpened = false">
                        <span class="icons icon-Vector"></span>
                        Notifications
                    </a>
                    <a
                        class="list-group-item light fs-18"
                        [routerLink]="['/', 'corporate', 'business-center', 'overview']"
                        (click)="isSideMenuOpened = false"
                    >
                        <mat-icon class="fs-18 va-middle">insights</mat-icon>
                        Business Center
                    </a>
                    <button
                        style="font-size: 12px; margin: 5px 20px"
                        (click)="onNewRide(); isSideMenuOpened = false"
                        class="animate-show btn btn-primary"
                    >
                        New Ride Request &nbsp;
                        <mat-icon class="send">send</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="col-sm-1"></div> -->
<div ui-view class="col-sm-12"></div>

<div class="notification-circle bottom" [class.expand]="expand_bottom_notification_circle"></div>
<div class="notification-circle top" [class.expand]="expand_top_notification_circle"></div>

<!--------------------------- Start - MODAL SECTION --------------------------->

<div id="profile_detail" class="modal fade" role="dialog" data-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 550px">
        <div class="modal-content sidenav">
            <div class="profile_header">
                <div class="row mlr-2">
                    <div class="col-auto p-0 pb-1 my-0 active">My Profile</div>
                    <div class="col-auto mlr-2 p-0 pb-2 my-0">
                        <img src="assets/images/divider_black.svg" alt="divider" />
                    </div>
                    <div class="col-auto p-0 pb-1 my-0 mr-auto">User ID # 342569</div>
                </div>
            </div>

            <hr />

            <div class="profile_content mlr-2 my-4" style="min-height: 250px">
                <div [hidden]="editMode">
                    <div class="row">
                        <div class="col-auto mr-auto" style="max-width: 60%">
                            <div class="title">General Information</div>

                            <div>
                                <div>
                                    <span class="semiTitle">Name:</span>
                                    <span class="subTitle">
                                        {{ driverDetails?.driver_name }}
                                    </span>
                                </div>
                                <div>
                                    <span class="semiTitle">Email:</span>
                                    <span class="subTitle">
                                        {{ driverDetails?.driver_email }}
                                    </span>
                                </div>
                                <div>
                                    <span class="semiTitle">Phone:</span>
                                    <span class="subTitle">
                                        {{ driverDetails?.driver_mobile }}
                                    </span>
                                </div>
                                <div>
                                    <span class="semiTitle">Address:</span>
                                    <span class="subTitle">
                                        {{ driverDetails?.address }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <img
                                [src]="driverDetails?.driver_image || driverDetails?.image"
                                onError="src= '/assets/images/default.png';"
                                id="dprofileimageone"
                            />
                        </div>
                    </div>

                    <div class="mt-3">
                        <div class="title">Other Information</div>
                        <div class="subtitle">-</div>
                    </div>

                    <div class="mt-3">
                        <div class="title">User type</div>
                        <div class="subtitle">Active/Regular</div>
                    </div>
                </div>

                <div [hidden]="!editMode" id="editinfo">
                    <div class="d-flex justify-content-between title" style="font-size: 16px">
                        <div class="align-self-center">Edit Profile</div>
                        <div>
                            <a (click)="closeEdit()">
                                &nbsp;
                                <i class="fa fa-times"></i>
                            </a>
                        </div>
                    </div>

                    <hr />
                    <div class="mt-4">
                        <p class="subtitle">Edit general information</p>
                        <div class="input-container position-relative">
                            <i class="fa fa-map-marker icon position-absolute"></i>
                            <input
                                class="input-field autocomplete"
                                id="base_location"
                                type="search"
                                autocomplete="off"
                                [(ngModel)]="pop.address"
                                [ngModelOptions]="{ standalone: true }"
                                required
                            />
                        </div>
                    </div>
                    <!-- <button type="submit" class="btn btn-primary btn-sm" style="color:#fff;">Update</button> -->
                    <!-- </form> -->
                </div>
            </div>

            <div class="profile_bottom">
                <hr />
                <div [hidden]="editMode" class="my-4 mlr-2">
                    <span (click)="editProfile()" class="cursor-pointer">
                        Edit Profile &nbsp;&nbsp;
                        <mat-icon>edit</mat-icon>
                    </span>
                </div>
                <div [hidden]="!editMode" class="m-4">
                    <span (click)="submitDetails()" class="cursor-pointer">
                        Update Profile &nbsp;&nbsp;
                        <mat-icon>edit</mat-icon>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    id="loading"
    class="modal"
    style="z-index: 1250 !important; display: block"
    [hidden]="!utilityService.loading"
    role="dialog"
    data-keyboard="false"
>
    <div class="modal-dialog modal-dialog-auto modal-dialog-centered">
        <div class="loaders">
            <div class="loaders-wrapper">
                <img src="assets/images/custom_loader.svg" alt="loading..." />
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade touch-popup"
    id="mas_login"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-backdrop="static"
>
    <div
        class="modal-dialog modal-dialog-auto modal-dialog-centered modal-lg"
        role="document"
        style="min-width: 630px !important; max-width: fit-content !important"
    >
        <div class="modal-content">
            <div class="touch-header pt-2">
                <div class="mx-5 my-4">
                    <div class="p-0 pb-1 my-0 d-flex justify-content-between">
                        <div class="medium-text dark-color">Enter MAS API Key</div>
                        <div class="blue-color cursor-pointer">More Info about MAS</div>
                    </div>
                </div>
            </div>

            <hr class="light" />
            <div class="bg-light-blue">
                <div class="touch-content mx-5 my-4 py-2">
                    <div class="d-flex justify-content-center">
                        <div class="mx-2 align-self-center fs-13">Provider API Key</div>
                        <div class="mx-2">
                            <!-- <input type="text" class="form-control fs-13" placeholder="Enter your API key here"> -->
                            <input type="text" class="touch-input fs-13 w-100 p-1 px-2" placeholder="Enter your API key here" #mas_api_key ngModel />
                        </div>
                    </div>
                    <div class="text-secondary fs-11 mt-4">Note: You can always update your API key in your settings whenever needed.</div>
                </div>
            </div>

            <hr class="light" />
            <div class="touch-bottom mx-5 my-3" align="center">
                <button class="btn btn-secondary border-0 font-light btn-light text-white m-2 px-3 py-1" data-dismiss="modal">Cancel</button>

                <button class="btn btn-primary border-0 font-light m-2 px-3 py-1" (click)="on_provider_connect(mas_api_key.value)">Submit</button>
            </div>
        </div>
    </div>
</div>

<div (click)="onClickOutlet()">
    <ng-container *ngIf="ignore_SideNav_Bar.includes(router.url?.split('?')[0] || '')">
        <router-outlet></router-outlet>
    </ng-container>

    <ng-container *ngIf="!ignore_SideNav_Bar.includes(router.url?.split('?')[0] || '')">
        <div class="container page page_outer no-select" id="Trips">
            <div class="d-flex bd-highlight">
                <div class="bd-highlight">
                    <app-sidenav></app-sidenav>
                </div>

                <div class="flex-grow-1 bd-highlight">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div
    class="modal fade p-2"
    id="edit_car_affiliation"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-backdrop="static"
>
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 626px">
        <div class="modal-content selection-dialog">
            <CarBaseAffiliation (onCarAffiliationSave)="update_car_base($event)" [placeholder]="current_car_base"></CarBaseAffiliation>
        </div>
    </div>
</div>

<ng-template #providerDropdown>
    <a class="dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <!-- <img src="/assets/images/navbar_provider.svg" /> -->
        <img src="/assets/images/navbar_car_base.svg" />
        &nbsp;
        <span class="ipad-hidden">My Car Base</span>
    </a>
    <div class="dropdown-menu dropdown-menu-right user provider network-menu mt-0 py-3" aria-labelledby="navbarDropdown">
        <div class="row-inline row mx-0 py-2 cursor-pointer">
            <div class="col-auto pr-0">
                <!-- <img src="assets/images/person.svg" alt="person_icon"> -->
                <i class="fa fa-user dynamic_padding mr-3" style="color: grey; font-size: 16px"></i>

                <img src="assets/images/divider_blue_small.svg" alt="divider_blue" />
            </div>
            <div class="col-auto input-box" (click)="this.utilityService.toggleMASUI.next()">
                <div>
                    <span class="mr-3 cursor-pointer" [title]="clientInfo?.business_name">
                        {{ clientInfo?.business_name | slice: 0:20 || 'Not found' }}
                        <span *ngIf="clientInfo?.business_name?.length > 20">...</span>
                    </span>

                    <img src="assets/images/divider_blue_small.svg" alt="divider_blue" />
                </div>
            </div>
            <div class="col-auto my-auto touch pl-0 ml-0" (click)="on_edit_car_affiliation()">
                <span class="mr-2">Edit Default</span>
            </div>
        </div>
        <div class="row-inline row mx-0 py-2 cursor-pointer" (click)="start_mas_session()" *ngIf="is_mas_dispatcher">
            <div class="col-auto pr-0">
                <!-- <img src="assets/images/person.svg" alt="person_icon"> -->
                <i class="fa fa-user dynamic_padding mr-3" style="color: grey; font-size: 16px"></i>

                <img src="assets/images/divider_blue_small.svg" alt="divider_blue" />
            </div>
            <div class="col-auto input-box">
                <div>
                    <span class="mr-3 cursor-pointer">MAS</span>

                    <img src="assets/images/divider_blue_small.svg" alt="divider_blue" />
                </div>
            </div>
            <div class="col-auto my-auto touch pl-0 ml-0">Start Session</div>
        </div>
    </div>
</ng-template>

<ng-template #driverSearchPopover>
    <div class="touch-popover p-2 cmodal">
        <div class="touch-box">
            <div class="py-1">
                <app-search-box [searchFormControl]="driver_search_control" placeholder="Search Driver" extraClass=""></app-search-box>
            </div>
            <div class="touch-menu auto">
                <!-- ['/', 'corporate', 'drivers', 'driver-detail', driver?.driver_id] -->
                <ng-container *ngIf="!driverLoading">
                    <div *ngFor="let driver of drivers" class="touch-item fs-13 cursor-pointer" (click)="visitDriverPage(driver?.driver_id)">
                        {{ driver?.driver_name }}
                    </div>
                </ng-container>

                <div *ngIf="!driverLoading && !drivers?.length" class="fs-13">
                    <div class="text-grey">No driver found</div>
                </div>

                <div *ngIf="driverLoading" class="mx-2">
                    <app-loading *ngFor="let item of [0, 1, 2, 3, 4, 5]" height="20px" borderRadius="2px" type="placeholder"></app-loading>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #riderSearchPopover>
    <div class="touch-popover p-2 cmodal">
        <div class="touch-box">
            <div class="py-1">
                <app-search-box [searchFormControl]="riderSearchForm" placeholder="Search Rider" extraClass=""></app-search-box>
            </div>
            <div class="touch-menu auto">
                <!-- ['/', 'corporate', 'drivers', 'driver-detail', driver?.driver_id] -->
                <ng-container *ngIf="!rider_loading">
                    <div *ngFor="let rider of riderList" class="touch-item fs-13 cursor-pointer" (click)="visitRiderPage(rider?.user_id)">
                        {{ rider?.user_name }}
                        <span class="fs-9 ml-1">{{ rider.user_mobile }}</span>
                    </div>
                </ng-container>

                <div *ngIf="!rider_loading && !riderList?.length" class="fs-13">
                    <div class="text-grey">No driver found</div>
                </div>

                <div *ngIf="rider_loading" class="mx-2">
                    <app-loading *ngFor="let item of [0, 1, 2, 3, 4, 5]" height="20px" borderRadius="2px" type="placeholder"></app-loading>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tripSearchPopover>
    <div class="touch-popover p-2 cmodal">
        <div class="touch-box">
            <div class="py-1">
                <app-search-box [searchFormControl]="trip_search_control" placeHolder="Search Invoice or Trip Leg" extraClass=""></app-search-box>
            </div>
            <div class="touch-menu auto overflow-auto">
                <ng-container *ngIf="!search_trip_loading">
                    <div *ngFor="let trip of tripSearchList" class="touch-item fs-13 cursor-pointer" (click)="visitTripDetailPage(trip)">
                        <div>{{ trip?.invoice_number }} | {{ trip?.user_name || '' }}</div>
                        <div class="fs-9 ml-1">Leg: {{ trip?.trip_leg_id }} | Id: {{ trip?.mas_trip_id }}</div>
                    </div>
                </ng-container>

                <div *ngIf="!search_trip_loading && !tripSearchList?.length" class="fs-13">
                    <div class="text-grey">No trip found</div>
                </div>

                <div *ngIf="search_trip_loading" class="mx-2">
                    <app-loading *ngFor="let item of [0, 1, 2, 3, 4, 5]" height="20px" borderRadius="2px" type="placeholder"></app-loading>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #profileDropdownPopover>
    <div class="touch-popover cmodal">
        <div class="touch-box profile-dropdown">
            <div class="bg-cyan w-100 p-4 text-dark-blue name-detail">
                <div *ngIf="corp_Type == 1 && selectedCorp">
                    <div class="fs-16 fw-600">
                        {{ (selectedCorp?.business_name ? selectedCorp?.business_name : driverDetails?.first_name) | titlecase }}
                    </div>
                </div>

                <div *ngIf="!selectedCorp">
                    <div class="fs-16 fw-600">{{ driverDetails?.first_name }}</div>
                    <div class="fs-14">{{ driverDetails?.driver_email }}</div>
                </div>
                <ng-container *ngIf="corp_Type == 1">
                    <ng-container *ngIf="ipadSize || mobileSize">
                        <span *ngIf="selectedCorp" class="back-superCorp" (click)="backToSuperCorp()">Back to SuperCorp</span>
                        <span *ngIf="selectedCorp">|</span>
                        <span *ngIf="!isSuperCorpAccess" class="back-superCorp" (click)="mobileSelectCorp($event, 'open')">Select Corporate</span>
                        <span *ngIf="isSuperCorpAccess" class="back-superCorp" (click)="mobileSelectCorp($event, 'close')">Back</span>
                    </ng-container>
                </ng-container>
            </div>

            <ng-container *ngIf="isSuperCorpAccess">
                <div class="p-3">
                    <div class="w-100 search-box light">
                        <span class="input-group">
                            <div class="d-flex w-100">
                                <div class="flex-fill">
                                    <input class="m-0 border-0 w-100" [formControl]="searchControl" type="text" placeholder="Search Corporate" />
                                </div>
                                <div>
                                    <i *ngIf="!searchControl.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                    <mat-icon
                                        [class.disabled-text]="searchControl?.disabled"
                                        *ngIf="searchControl.value"
                                        (click)="resetSearch($event)"
                                        class="cursor-pointer"
                                        style="vertical-align: middle"
                                    >
                                        close
                                    </mat-icon>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div class="car-base-list">
                        <ng-container *ngFor="let item of car_affiliation">
                            <div
                                class="dropdown-item cursor-pointer"
                                (click)="selectCorpAccount(item)"
                                [ngClass]="{ selectedCorp: item.corporate_id == selectedCorp?.corporate_id }"
                            >
                                <span class="di-title">
                                    {{ item?.business_name | slice: 0:20 }}
                                    <span *ngIf="item.business_name?.length > 20">... &nbsp;</span>
                                </span>
                                <span class="di-subtitle">
                                    {{ item?.mobile }}
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!isSuperCorpAccess">
                <ng-container *ngIf="ipadSize || mobileSize">
                    <hr />
                    <div class="p-3 menu-item">
                        <div [routerLink]="['/', 'corporate', 'notifications', 'list']" (click)="closeProfileDropdown()">
                            Notifications
                            <span class="text-blue-secondary">{{ totalNotifications }}</span>
                        </div>
                        <div [routerLink]="['/', 'corporate', 'cards']" (click)="closeProfileDropdown()">Settings</div>
                    </div>
                    <hr />
                    <div class="p-3">
                        <div class="fs-16 mb-2 px-1 text-dark-blue">Network</div>
                        <div class="menu-item">
                            <div [routerLink]="['/', 'corporate', 'drivers', 'all-drivers']" (click)="closeProfileDropdown()">Driver</div>
                            <div [routerLink]="['/', 'corporate', 'riders', 'my-riders']" (click)="closeProfileDropdown()">Rider</div>
                        </div>
                    </div>
                    <hr />
                    <div class="p-3">
                        <div class="fs-16 mb-2 px-1 text-dark-blue">Car Base Affiliation</div>
                        <div class="text-grey px-2">
                            <span class="fw-normal">
                                {{ clientInfo?.business_name | slice: 0:20 || 'Not found' }}
                                <ng-container *ngIf="clientInfo?.business_name?.length > 20">...</ng-container>
                            </span>
                            <span
                                class="text-blue-secondary cursor-pointer"
                                (click)="on_edit_car_affiliation(); profileDropdownPopoverReference.hide()"
                            >
                                Edit Default
                            </span>
                        </div>
                    </div>
                    <hr />
                </ng-container>
                <div class="p-3">
                    <div class="menu-item">
                        <div (click)="openProfileDetail(); closeProfileDropdown()">Profile</div>
                        <div (click)="logoutConfirm(); closeProfileDropdown()" class="fw-600 fs-16">Logout</div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>

<!-- All Approve Account -->
<ng-template #approvedCarBase>
    <div class="touch-popover cmodal">
        <div class="touch-box profile-dropdown">
            <div class="bg-cyan w-100 p-4 text-dark-blue name-detail">
                <div class="fs-16 fw-600">
                    SuperCorp Access
                    <span *ngIf="selectedCorp" class="back-superCorp" (click)="backToSuperCorp()">Back to SuperCorp</span>
                </div>
            </div>
            <div class="p-3">
                <div class="w-100 search-box light">
                    <span class="input-group">
                        <div class="d-flex w-100">
                            <div class="flex-fill">
                                <input class="m-0 border-0 w-100" [formControl]="searchControl" type="text" placeholder="Search Corporate" />
                            </div>
                            <div>
                                <i *ngIf="!searchControl.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                <mat-icon
                                    [class.disabled-text]="searchControl?.disabled"
                                    *ngIf="searchControl.value"
                                    (click)="resetSearch($event)"
                                    class="cursor-pointer"
                                    style="vertical-align: middle"
                                >
                                    close
                                </mat-icon>
                            </div>
                        </div>
                    </span>
                </div>
                <div class="car-base-list">
                    <ng-container *ngFor="let item of car_affiliation">
                        <div
                            class="dropdown-item cursor-pointer"
                            (click)="selectCorpAccount(item)"
                            [ngClass]="{ selectedCorp: item.corporate_id == selectedCorp?.corporate_id }"
                        >
                            <span class="di-title">
                                {{ item?.business_name | slice: 0:20 }}
                                <span *ngIf="item.business_name?.length > 20">... &nbsp;</span>
                            </span>
                            <span class="di-subtitle">
                                {{ item?.mobile }}
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-template>
